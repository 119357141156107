
  import { channelColors } from '@/utils/channelColors'
  import { deepCopy, localToUtc, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component, Prop } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'

@Component
  export default class SupervisorSalesPurchases extends SupervisorDashboard {
  @Prop({ default: true, type: Boolean }) showGeneralResults

// Methods
  beforeCreate () {
    this.getData = async () => {
      await this.getGeneralResults()
    }
  }

  fixPurchaseFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { auto, executive } = corrected[i]
      const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
      const executiveFullName = `${executive.person.firstName.trim()} ${executive.person.surname.trim()}`
      corrected[i].stockCreated = dayjs(corrected[i].stockCreated).format('DD/MM/YY - HH:mm')
      corrected[i].executive.fullName = executiveFullName
      corrected[i] = {
        ...corrected[i],
        carFullName,
        executiveFullName,
      }
    }

    return corrected
  }

  fixCreditFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { auto, executive } = corrected[i]
      const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
      const executiveFullName = `${executive.person.firstName.trim()} ${executive.person.surname.trim()}`
      corrected[i].signedDate = dayjs(corrected[i].signedDate).format('DD/MM/YY - HH:mm')
      corrected[i].executive.fullName = executiveFullName
      corrected[i] = {
        ...corrected[i],
        carFullName,
        executiveFullName,
      }
    }

    return corrected
  }

  fixSaleFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { autoDescription, year, executive, executiveName } = corrected[i]
      const carFullName = `${autoDescription} ${year}`
      corrected[i].soldDate = dayjs(corrected[i].soldDate).format('DD/MM/YY - HH:mm')
      corrected[i].executive = {
        id: executive,
        name: executiveName,
      }
      corrected[i] = {
        ...corrected[i],
        carFullName,
      }
    }

    return corrected
  }

  normalicePurchases (records) {
    return this.setChannelColors(records)
      .map(record => {
        const { executive } = record
        const { person } = executive
        record.executive = {
          id: executive.id,
          name: `${person.firstName} ${person.surname}`,
        }
        return record
      })
  }

  setChannelColors (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { channel } = corrected[i]
      channel.color = channelColors[channel.name]
      corrected[i].channel = channel
    }
    return corrected
  }

  normaliceReserves (records) {
    return records.map(record => {
      const { executive, executiveName } = record
      record.executive = {
        id: executive,
        name: executiveName,
      }
      return record
    })
  }

  normaliceSales (records) {
    return this.normaliceReserves(this.setChannelColors(records))
  }

  normaliceReservesQuery (records) {
    return records.map(record => {
      const { executive } = record.saleOrder.deal.lead
      const { person } = executive
      record.executive = {
        id: executive.id,
        name: `${person.firstName} ${person.surname}`,
      }
      return record
    })
  }

  sortBySignedDate (records) {
    return records.sort((prev, next) => {
      if (dayjs(prev.signedDate).isBefore(dayjs(next.signedDate))) {
        return 1
      } else if (dayjs(prev.signedDate).isAfter(dayjs(next.signedDate))) {
        return -1
      }
      return 0
    })
  }

  sortByStockCreated (records) {
    return records.sort((prev, next) => {
      if (dayjs(prev.stockCreated).isBefore(dayjs(next.stockCreated))) {
        return 1
      } else if (dayjs(prev.stockCreated).isAfter(dayjs(next.stockCreated))) {
        return -1
      }
      return 0
    })
  }

  sortBySoldDate (records) {
    return records.sort((prev, next) => {
      if (dayjs(prev.soldDate).isBefore(dayjs(next.soldDate))) {
        return 1
      } else if (dayjs(prev.soldDate).isAfter(dayjs(next.soldDate))) {
        return -1
      }
      return 0
    })
  }

  transformUtility (records) {
    const { getObjectAttribute } = this
    const { start, end } = this.lastYearDates

    const recordsCopy = deepCopy(records)

    for (let i = 0; i < recordsCopy.length; i++) {
      this.$set(recordsCopy[i], 'records', recordsCopy[i].records?.records?.nodes || recordsCopy[i].records?.records || recordsCopy[i].records?.records || recordsCopy[i].records || [])
    }

    const purchasesRecords = recordsCopy?.filter(record => record.name === 'Compras')[0]
    const salesRecords = recordsCopy?.filter(record => record.name === 'Ventas')[0]
    const creditsRecords = recordsCopy?.filter(record => record.name === 'Créditos')[0]

    if (!purchasesRecords || !salesRecords || !creditsRecords) return

    let newDate = dayjs(start)
    const limitRange = [newDate]
    while (newDate.isBefore(dayjs(end))) {
      newDate = newDate.add(1, 'month')
      if (newDate.isBefore(dayjs(end))) {
        limitRange.push(newDate)
      }
    }
    // Directa
    const directUtility = limitRange.map(date => {
      const sales = salesRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, salesRecords.pathToDate))).isSame(date, 'month'))
      const purchases = purchasesRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, purchasesRecords.pathToDate))).isSame(date, 'month'))
      let utility = 0
      sales.forEach(sale => utility += getObjectAttribute(sale, salesRecords.pathToValue) || 0)
      purchases.forEach(purchase => utility -= getObjectAttribute(purchase, purchasesRecords.pathToValue) || 0)
      return {
        date: localToUtc(date).format('YYYY-MM-DDTHH:mm:ss'),
        utility,
      }
    })
    // Creditos
    const creditsUtility = limitRange.map(date => {
      const credits = creditsRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, creditsRecords.pathToDate))).isSame(date, 'month'))
      let utility = 0
      credits.forEach(credit => utility += getObjectAttribute(credit, creditsRecords.pathToValue))
      return {
        date: date.format('YYYY-MM-DD'),
        utility,
      }
    })

    return [
      {
        name: 'Directa',
        records: directUtility,
        color: '#FAD901',
        pathToDate: ['date'],
        pathToValue: ['utility'],
        type: 'bar',
      },
      {
        name: 'Créditos',
        records: creditsUtility,
        color: '#7D67BC',
        pathToDate: ['date'],
        pathToValue: ['utility'],
        type: 'bar',
      },
    ]
  }

  // Getters

  // Watchers
  }
